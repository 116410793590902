import React, {Component} from 'react'
import {Container, Row, Col} from 'reactstrap'
import {Link} from "gatsby";
import Layout from 'components/Layout/Layout'

class NotFoundPage extends Component {
    render() {
        return (
            <Layout>
                <Link to={'/'} className={'subPages__no-link'}>
                    <div className="section-intro">
                    </div>
                </Link>

                <div className="section-sign-up">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="section-sign-up__wrap">
                                    <h3 className={`narrow orange`}>404 - The page is not found</h3>
                                    <p>Go back to <Link to={`/`}>homepage</Link></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        )
    }
}

export default NotFoundPage
